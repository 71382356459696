import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const RiddleText = styled(Typography)(({ theme }) => ({
  marginBottom: "40px",
  fontSize: "18px",
  color: "#02434A",
  maxWidth: "70%",
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
  "@media (max-width:600px)": {
    maxWidth: "100%",
  },
}));

const AnswerInput = styled(TextField)(({ theme }) => ({
  backgroundColor: "white",
  marginBottom: "20px",
  width: "100%",
  maxWidth: "400px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
}));

const HiddenInput = styled("input")({
  display: "none",
});

const RiddleComponent = ({ riddle, list, photo, onResponseChange, reset }) => {
  const [answer, setAnswer] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    setAnswer("");
    setFile(null);
  }, [reset]);

  const handleAnswerChange = (event) => {
    setAnswer(event.target.value);
    onResponseChange(event.target.value, file);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    onResponseChange(answer, selectedFile);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {photo && <span style={{ fontStyle: "italic" }}>Take a picture</span>}
        <RiddleText>{riddle}</RiddleText>
        {list && (
          <RiddleText component="div">
            <ol>
              {list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ol>
          </RiddleText>
        )}
        <AnswerInput
          variant="outlined"
          value={answer}
          onChange={handleAnswerChange}
          disabled={photo}
          InputProps={{
            startAdornment: photo && (
              <InputAdornment position="start">
                <label htmlFor="upload-photo">
                  <CameraAltIcon style={{ cursor: "pointer" }} />
                </label>
                <HiddenInput
                  id="upload-photo"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default RiddleComponent;
