import React from "react";
import { Button, Box } from "@mui/material";
import { styled } from "@mui/system";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";

const Container = styled(Box)(({ customStyles }) => ({
  textAlign: "center",
  padding: "10px",
  ...customStyles,
}));

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "10px 50px",
  fontSize: "18px",
  backgroundColor: "#02434A",
  color: "#E0FFF8",
  "&:hover": {
    backgroundColor: "#02393A",
    transform: "scale(1.05)",
  },
  "@media (max-width:600px)": {
    fontSize: "16px",
    padding: "8px 40px",
  },
}));

const Passed = () => {
  const [confettiPieces, setConfettiPieces] = React.useState(200);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setConfettiPieces(0);
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box>
      <Confetti numberOfPieces={confettiPieces} recycle={true} />
      <Container
        customStyles={{
          fontSize: 80,
          fontWeight: "bold",
          maxWidth: "70%",
          "@media (max-width:600px)": { fontSize: 40, maxWidth: "100%" },
        }}
      >
        Congratulations!
      </Container>
      <Container
        customStyles={{
          marginBottom: "20px",
          fontSize: "20px",
          "@media (max-width:600px)": { fontSize: "16px" },
        }}
      >
        You have won a free dessert coupon from
      </Container>
      <Container
        customStyles={{
          marginBottom: "20px",
          fontSize: "20px",
          "@media (max-width:600px)": { fontSize: "16px" },
        }}
      >
        Hana Hana
      </Container>
      <Container
        customStyles={{
          fontWeight: "bold",
          marginBottom: "40px",
          fontSize: "20px",
          "@media (max-width:600px)": { fontSize: "16px" },
        }}
      >
        To claim and store the coupon, please register/sign in to Itini.
      </Container>
      <Link to='https://itini.ai/sign-in'>
        <CustomButton variant='contained'>Claim Reward</CustomButton>
      </Link>
    </Box>
  );
};

export default Passed;
