import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import BackgroundImage from "../assets/images/riddle-background.png";
import HanaHana from "../assets/images/hana-hana.png";
import ItiniLogo from "../assets/images/itini-logo.png";
import Passed from "../Components/Passed";
import Failed from "../Components/Failed";

const success = true;

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  backgroundColor: "#E0FFF8",
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  textAlign: "center",
  padding: "20px",
  boxSizing: "border-box",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    padding: "40px",
  },
  "@media (max-width:600px)": {
    padding: "10px",
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "20px",
  left: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  [theme.breakpoints.up("md")]: {
    "& img": {
      height: "30px",
      marginRight: "10px",
    },
  },
  "@media (max-width:600px)": {
    position: "relative",
    top: "unset",
    left: "unset",
    maxWidth: "70%",
    justifyContent: "center",
  },
}));

const Result = () => {
  return (
    <Container>
      <LogoContainer>
        <img src={HanaHana} alt="Hana Hana Logo" />
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          X
        </Typography>
        <img src={ItiniLogo} alt="Itini Logo" />
      </LogoContainer>
      {success ? <Passed /> : <Failed />}
    </Container>
  );
};

export default Result;
