import React from "react";
import { Button, Box } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const Container = styled(Box)(({ customStyles }) => ({
  textAlign: "center",
  padding: "10px",
  ...customStyles,
}));

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "10px 50px",
  fontSize: "18px",
  backgroundColor: "#02434A",
  color: "#E0FFF8",
  "&:hover": {
    backgroundColor: "#02393A",
    transform: "scale(1.05)",
  },
  "@media (max-width:600px)": {
    fontSize: "12px",
    padding: "5px 20px",
  },
}));

const Failed = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <Container
        customStyles={{
          fontSize: 80,
          fontWeight: "bold",
          maxWidth: "70%",
          "@media (max-width:600px)": { fontSize: 40, maxWidth: "100%" },
        }}
      >
        Next time 🥺
      </Container>
      <Container
        customStyles={{
          marginBottom: "20px",
          fontSize: "20px",
          "@media (max-width:600px)": { fontSize: "16px" },
        }}
      >
        Aw! Sorry you didn’t win this time. You can try again or try your luck
        for another local business.
      </Container>
      <Box>
        <Link to='/quiz' style={{ textDecoration: "none" }}>
          <CustomButton sx={{ marginRight: 1 }} variant='contained'>
            Try Again
          </CustomButton>
        </Link>
        <Link to='/' style={{ textDecoration: "none" }}>
          <CustomButton variant='contained'>Somewhere Else</CustomButton>
        </Link>
      </Box>
    </Box>
  );
};

export default Failed;
