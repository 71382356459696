import React, { useState } from "react";
import RiddleComponent from "../Components/RiddleComponent";
import { Button, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import BackgroundImage from "../assets/images/riddle-background.png";
import HanaHana from "../assets/images/hana-hana.png";
import ItiniLogo from "../assets/images/itini-logo.png";
import { useNavigate } from "react-router-dom";

const riddles = [
  {
    question:
      "I am sweet and round, with a chewy skin, A cold surprise awaits within. From Japan, I bring delight, A frozen treat that's just right. What am I?",
  },
  {
    question:
      "Here are three clues for this dish: Comes with a variety of small, circular pieces. Served with wasabi and pickled ginger on the side. You might find this at a seafood buffet, with various colorful pieces arranged on a large plate.",
  },
  {
    question:
      "I’m crispy on top, but soft underneath, With rice that’s warm and egg that’s sweet. A tender cutlet, fried to golden brown, In a savory bowl, I wear my crown. What am I?",
  },
  {
    question:
      "Rich broth and noodles thin With pork, egg and scallion Served in a bowl and eaten with a spoon Which of these will make you swoon?",
  },
  {
    question:
      "Find the missing letter from these movie titles and unscramble them to find the menu item:",
    list: [
      "Titanc",
      "Marle and Me",
      "Blae Runner",
      "A New Hpe",
      "Singing in the Ain",
      "The Social Networ",
      "The Silence of the Lmbs",
      "Csablanca",
    ],
  },
];

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  backgroundColor: "#E0FFF8",
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  textAlign: "center",
  padding: "20px",
  boxSizing: "border-box",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    padding: "40px",
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "20px",
  left: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  [theme.breakpoints.up("md")]: {
    "& img": {
      height: "30px",
      marginRight: "10px",
    },
  },
  "@media (max-width:600px)": {
    position: "relative",
    top: "unset",
    left: "unset",
    maxWidth: "70%",
    justifyContent: "center",
    marginBottom: "30px"
  },
}));

const NextButton = styled(Button)(({ theme }) => ({
  fontSize: "16px",
  color: "#02434A",
  marginTop: "20px",
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
}));

const Quiz = () => {
  const navigate = useNavigate();
  const [currentRiddleIndex, setCurrentRiddleIndex] = useState(0);
  const [responses, setResponses] = useState([]);

  const handleNextClick = () => {
    setCurrentRiddleIndex((prevIndex) => prevIndex + 1);
  };

  const handleResponseChange = (response, file) => {
    const updatedResponses = [...responses];
    updatedResponses[currentRiddleIndex] = { response, file };
    setResponses(updatedResponses);
  };

  const handleSubmit = () => {
    console.log(responses);
    navigate("/result");
  };

  return (
    <Container>
      <LogoContainer>
        <img src={HanaHana} alt='Hana Hana Logo' />
        <Typography variant='h5' sx={{ fontWeight: "bold" }}>
          X
        </Typography>
        <img src={ItiniLogo} alt='Itini Logo' />
      </LogoContainer>
      {currentRiddleIndex < riddles.length ? (
        <>
          <RiddleComponent
            riddle={riddles[currentRiddleIndex].question}
            list={riddles[currentRiddleIndex].list}
            onResponseChange={handleResponseChange}
            reset={currentRiddleIndex}
          />
          <NextButton onClick={handleNextClick} endIcon={<span>→</span>}>
            Next
          </NextButton>
        </>
      ) : (
        <>
          <RiddleComponent
            riddle='In spring, I wear a pink and white dress, A fleeting beauty, I must confess. Beneath my branches, people convene, For photos of my lovely scene. Take a picture with me'
            photo
            onResponseChange={handleResponseChange}
            reset={currentRiddleIndex}
          />
          <NextButton onClick={handleSubmit} endIcon={<span>→</span>}>
            Next
          </NextButton>
        </>
      )}
    </Container>
  );
};

export default Quiz;
