import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  keyframes,
} from "@mui/material";
import { styled } from "@mui/system";
import HanaHana from "../assets/images/hana-hana.png";
import ItiniLogo from "../assets/images/itini-logo.png";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

const fadeIn = keyframes`
  0% { 
    opacity: 0; 
    transform: translateY(-50px) scale(0.8);
    visibility: hidden; 
  }
  1% {
    visibility: visible;
  }
  100% { 
    opacity: 1; 
    transform: translateY(0) scale(1); 
    visibility: visible;
  }
`;

const bounceIn = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(2, 67, 74, 0.7); }
  50% { box-shadow: 0 0 15px 5px rgba(2, 67, 74, 0.3); }
  100% { box-shadow: 0 0 0 0 rgba(2, 67, 74, 0); }
`;

const zoomIn = keyframes`
  0% { transform: scale(0); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
`;

const Container = styled(Box)(({ customStyles }) => ({
  textAlign: "center",
  padding: 10,
  animation: `${fadeIn} 1.5s ease-out forwards`,
  ...customStyles,
}));

const HighlightedText = styled("span")(({ theme }) => ({
  backgroundColor: "#02434A",
  color: "#E0FFF8",
  padding: "0 15px",
  borderRadius: "10px",
  animation: `${fadeIn} 3s ease-out forwards`,
  animationDelay: "6s",
  fontWeight: "bold",
  boxShadow: "0px 5px 15px rgba(2, 67, 74, 0.7)",
  visibility: "hidden",
}));

const CompanyLogo = styled("img")(({ theme }) => ({
  margin: "0 50px",
  animation: `${pulse} 2s infinite`,
  [theme.breakpoints.down("sm")]: {
    margin: "0 50px",
    width: "100px",
  },
}));

const StartButton = styled(Button)(({ theme }) => ({
  marginTop: 3,
  padding: "5px 50px",
  fontSize: "18px",
  backgroundColor: "#02434A",
  color: "#E0FFF8",
  boxShadow: "0px 5px 15px rgba(2, 67, 74, 0.7)",
  animation: `${bounceIn} 2.5s infinite ease-in-out`,
  transition: "transform 0.2s ease",
  "&:hover": {
    backgroundColor: "#02393A",
    transform: "scale(1.05)",
  },
  "@media (max-width:600px)": {
    fontSize: "16px",
  },
}));

const Welcome = () => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#E5D5C8",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto 1fr",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            "@media (max-width:600px)": {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <CompanyLogo src={HanaHana} alt='Hana Hana Logo' />
          <Typography
            sx={{
              fontSize: 60,
              fontWeight: "bold",
              "@media (max-width:600px)": { fontSize: 40 },
              animation: `${zoomIn} 2s ease-out forwards`,
            }}
          >
            X
          </Typography>
          <CompanyLogo src={ItiniLogo} alt='Itini Logo' />
        </Box>
      </Container>
      <Container
        sx={{
          // animation: `${fadeIn} 1s forwards`,
          // animationDelay: "1s",
          // visibility: "hidden",
        }}
      >
        <TypeAnimation
          cursor={false}
          style={{
            whiteSpace: "pre-line",
            height: "150px",
            fontSize: 80,
            fontWeight: "bold",
            "@media (max-width:600px)": { fontSize: 50, marginTop: 0 },
          }}
          sequence={[`      Welcome\nto`]}
          speed={{ type: "keyStrokeDelayInMs", value: 250 }}
        />
      </Container>
      <Container
        sx={{
          fontSize: 80,
          fontWeight: "bold",
          "@media (max-width:600px)": { fontSize: 50, marginTop: 0 },
          animation: `${fadeIn} 3s ease-out forwards`,
          animationDelay: "5s",
          visibility: "hidden",
        }}
      >
        THE
        <HighlightedText
          sx={{
            backgroundColor: "#02434A",
            color: "#E0FFF8",
            boxShadow: "0px 5px 15px rgba(2, 67, 74, 0.7)",
            padding: "0 15px",
            borderRadius: "10px",
          }}
        >
          HUNT
        </HighlightedText>
      </Container>
      <Container
        sx={{
          animation: `${fadeIn} 3s ease-out forwards`,
          animationDelay: "8s",
          visibility: "hidden",
        }}
      >
        <Box display='flex' flexDirection='column' alignItems='center'>
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleCheckboxChange} />
            }
            required
            label='Agree to terms and conditions'
            sx={{
              fontSize: "20px",
              "@media (max-width:600px)": {
                fontSize: "16px",
              },
            }}
          />
          {checked ? (
            <Link to='/quiz' style={{ textDecoration: "none" }}>
              <StartButton variant='contained'>Start</StartButton>
            </Link>
          ) : (
            <StartButton variant='contained' disabled>
              Start
            </StartButton>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Welcome;
