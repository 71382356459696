import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource/ubuntu";
import Welcome from "./pages/Welcome";
import Quiz from "./pages/Quiz";
import Result from "./pages/Result";

const theme = createTheme({
  typography: {
    fontFamily: "Ubuntu, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#02434A",
    },
    secondary: {
      main: "#E0FFF8",
    },
    text: {
      primary: "#02434A",
    },
    background: {
      default: "white",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* <Route path='/' element={<LandingPage />} /> */}
          {/* <Route path='/book-new-itini' element={<ComingSoon />} /> */}
          <Route path='/' element={<Welcome />} />
          <Route path='/quiz' element={<Quiz />} />
          <Route path='/result' element={<Result />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
